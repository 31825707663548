<template>
  <div>
    <MyOrders />
  </div>
</template>

<script>
import MyOrders from "@/component/MyOrders/MyOrders.vue";

export default {
  name: "MyOrdersView",
  components: {
    MyOrders
  }
};
</script>