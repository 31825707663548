import moment from "moment";
import accounting from "accounting";
import VuetableFieldHandle from "vuetable-2/src/components/VuetableFieldHandle.vue";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence.vue";
import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue";
import common from "../../mixins/common";

export default [{
    name: "id",
    title: "ID",
    sortField: "id",
    width: "5%",
    titleClass: "right aligned",
    dataClass: "right aligned"
},
{
    name: "total_payable",
    sortField: "total_payable",
    title: '<i class="grey list outline icon"></i>Total Payable',
    width: "25%",
    filterable: true,
    togglable: true,
    formatter(value) {
        if(value){
            return  "Rs " + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return "Rs 0.00";
        }  
    },
},
{
    name: "created_at_format",
    sortField: "created_at",
    title: '<i class="grey list outline icon"></i>Order Date',
    width: "25%",
    filterable: true,
    togglable: true
},

{
    name: "status_label",
    sortField: "order_status",
    title: '<i class="grey list outline icon"></i>Status',
    width: "20%",
    filterable: true,
    togglable: true
},
{
    name: "custom-actions",
    width: "20%",
    titleClass: "center aligned",
    title: '<i class="grey list outline icon"></i>Actions',
    dataClass: "center aligned"

}
];