<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :loader="$store.state.spinerLoader"
      :color="$store.state.spinerColor"
    ></loading>
    <vue-snotify></vue-snotify>
    <CRow class="justify-content-center">
      <CCol sm="10" md="10">
        <CCard class="pt-3 pl-3 pr-3 card-color text-white">
          <CRow>
            <CCol md="2">
              <div class="pt-2"></div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <vuetable
                ref="Vuetable"
                :api-url="getUrl"
                :http-options="httpOptions"
                :fields="fields"
                pagination-path
                @vuetable:pagination-data="onPaginationData"
                :per-page="perPage"
                :css="{ sortableIcon: 'grey sort icon' }"
                :append-params="moreParams"
                @vuetable:load-success="loadSuccess"
                @vuetable:load-error="loadError"
                @vuetable:loading="tableLoading"
                class="dark-vue-table"
              >
                <template slot="tableHeader">
                  <vuetable-row-header></vuetable-row-header>
                </template>
                <template slot="custom-actions" scope="props">
                  <div class="form-group">
                    <CDropdown color="primary btn-sm" toggler-text="Actions">
                      <CDropdownItem
                        @click="
                          onActionClicked(
                            'view-order',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                      >
                        <CIcon name="cil-list" class="mr-2" text-white />
                        <div class="text-white">View Order</div>
                      </CDropdownItem>

                      <CDropdownItem
                        @click="
                          onActionClicked(
                            'print-receipt',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                        ><CIcon name="cil-list" class="mr-2" />
                        <div class="text-white">
                          My Order Receipt
                        </div></CDropdownItem
                      >
                    </CDropdown>
                  </div>
                </template>
              </vuetable>

              <CRow>
                <CCol md="12">
                  <div class="vuetable-pagination ui basic segment grid row">
                    <CCol md="4">
                      <vuetable-pagination-info
                        ref="paginationInfo"
                      ></vuetable-pagination-info>
                    </CCol>
                    <CCol md="8">
                      <vuetable-pagination
                        style="float: right"
                        ref="pagination"
                        @vuetable-pagination:change-page="onChangePage"
                      ></vuetable-pagination>
                    </CCol>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
    <!-- Order Items Modal - START -->
    <CModal
      title="Order Items"
      :show.sync="orderItemsModal"
      size="lg"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol md="12">
          <div
            v-if="orderItems.length > 0"
            class="table-wrapper-scroll-y my-custom-scrollbar"
          >
            <div class="table-responsive">
              <table class="table table-dark table-bordered">
                <tr>
                  <td>Item ID</td>
                  <td>Name</td>
                  <td>Qty.</td>
                  <td>Unit</td>
                  <!-- <td>Variation</td> -->
                  <td>Item Comment</td>
                  <td>Total Amount</td>
                  <td>Status</td>
                </tr>
                <tr v-for="(value, index) in orderItems" v-bind:key="index">
                  <td>{{ value.item_id }}</td>
                  <td>
                    {{ value.item.title }} -
                    {{
                      value.attribute && value.attribute.attribute_value
                        ? value.attribute.attribute_value.name
                        : ""
                    }}
                  </td>
                  <td>{{ value.item_count }}</td>
                  <td>{{ value.unit.name }}</td>
                  <!-- <td>{{ value.attribute ? value.attribute.name : "" }}</td> -->
                  <td>{{ value.item_comment }}</td>
                  <td>{{ value.item_total }}</td>
                  <td v-html="value.status_label"></td>
                </tr>
              </table>
            </div>
          </div>
          <div v-else>Results not found.</div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="orderItemsModal = false" variant="outline" color="dark"
          >Cancel</CButton
        >
      </template>
    </CModal>
    <!-- Order Items Modal - END -->

    <!--  Order Process Modal -->
    <CModal
      title="Order Status"
      :show.sync="orderProcessModal"
      size="lg"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol md="12">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </CCol>
      </CRow>
      <template #header>
        <h5>
          Are you sure want to change your order status to
          <CBadge color="primary">{{ orderStatusLabel }}</CBadge> ?
        </h5>
      </template>
      <template #footer>
        <CButton
          @click="
            orderProcessModal = false;
            changeOrderStatus();
          "
          color="primary"
          >Yes</CButton
        >
        <CButton
          @click="orderProcessModal = false"
          variant="outline"
          color="dark"
          >No</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2";
// import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePagination from "@/component/vueTable/VuetablePaginationBootstrap4";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo.vue";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import FieldsDef from "../MyOrders/OrderListTableDef";
import MyDetailRow from "@/component/vueTable/MyDetailRow";
import RowFilter from "@/component/vueTable/RowFilter.vue";
import { mapActions, mapState } from "vuex";
import commonMixin from "@/mixins/common";
import store from "../../store/store";

export default {
  name: "OrderList",
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    VuetableRowHeader,
    // RowFilter,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      status: true,
      statusFil: true,
      orderItemsModal: false,
      status_options: [
        {
          value: 0,
          label: "Pending Order",
        },
        {
          value: 1,
          label: "Order Processing",
        },
        {
          value: 2,
          label: "Ready to serve",
        },
        {
          value: 2,
          label: "Served",
        },
        {
          value: 4,
          label: "Order Canceled",
        },
        {
          value: 5,
          label: "Order Closed",
        },
      ],
      fields: FieldsDef,
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization:
            "Bearer " + localStorage.getItem("customer_access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "/customer/order_list",
      detailRow: MyDetailRow,
      paginationComponent: "vuetable-pagination",
      vuetableFields: false,
      loading: "",
      tableHeight: "500px",
      multiSort: true,
      perPage: 10,
      scrollbarVisible: false,
      parent_id: "",
      title: "",
      customerIdFil: "",
      rowId: "",
      orderProcessModal: false,
      orderStatus: "",
      orderStatusLabel: "",
      activeOrder: [],
      activeOrderItem: [],
    };
  },
  async mounted() {},
  computed: {
    ...mapState("login", ["tenant_id"]),
    ...mapState("order", ["orderItems"]),
    ...mapState("user", ["user_data"]),
  },
  mixins: [commonMixin],
  methods: {
    ...mapActions("order", [
      "getOrderItemsData",
      "clearData",
      "updateOrderStatus",
      "updateOrderItemStatus",
      "printReceipt",
    ]),

    loadSuccess() {
      //load success event
      this.isLoading = false;
    },
    tableLoading() {
      this.isLoading = true;
    },
    loadError() {
      //table load error
      /* redirect to login */
      this.isLoading = false;
      store.commit("login/clearLogin");
      this.$router.push({
        path: "/login",
      });
    },
    resetCreateForm() {
      this.parent_id = "";
      this.title = "";
    },
    doFilter() {
      this.moreParams = {
        customer_id: this.customerIdFil,
        status: this.statusFil,
      };
      Vue.nextTick(() => this.$refs.Vuetable.refresh());
    },
    resetFilter() {
      this.customerIdFil = "";
      this.statusFil = "";
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.Vuetable.refresh());
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.Vuetable.changePage(page);
    },
    async onActionClicked(action, data, rowIndex) {
      this.activeOrder = data;
      if (action == "view-order") {
        this.orderItemsModal = true;
        this.getOrderItemsData(data);
      } else if (action == "print-receipt") {
        const request_data = {
          order_id: this.activeOrder.id,
        };
        this.printReceipt(request_data).then((data) => {
          if (data.data.error) {
          } else {
            window.open(data.data, "_blank");
          }
        });
      }
    },
    onSubActionClicked(action, obj) {
      let request_data = {
        order_item_id: obj.id,
      };
      if (action == "start-prepare") {
        request_data.status = 1;
      } else if (action == "cancel") {
        request_data.status = 4;
      } else if (action == "complete") {
        request_data.status = 2;
      } else if (action == "serve") {
        request_data.status = 3;
      }

      this.updateOrderItemStatus(request_data).then((data) => {
        if (data.data.error) {
        } else {
          this.getOrderItemsData(this.activeOrder);
        }
      });
    },
    changeOrderStatus() {
      const request_data = {
        order_id: this.activeOrder.id,
        status: this.orderStatusId,
      };

      this.updateOrderStatus(request_data).then((data) => {
        if (data.data.error) {
        } else {
          this.orderProcessModal = false;
        }
        Vue.nextTick(() => this.$refs.Vuetable.refresh());
      });
    },
  },
};
</script>

<style>
select.search-custom option {
  background-color: black !important;
}
.form-control {
  border: 1px solid;
  color: #ffffff !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.c-main {
  background-color: rgba(0, 0, 0, 0.808);
}

.table-item-view tr td {
  color: #ffffff !important;
  background-color: rgb(0, 0, 0);
}
.dark-vue-table tr td {
  color: #ffffff !important;
}
.dark-vue-table thead th {
  color: #ffffff !important;
}
</style>

<style scoped>
.card-color {
  background-color: rgb(0, 0, 0);
}

.order-table {
  background-color: rgb(0, 0, 0);
}
</style>
